import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Hero from 'components/Hero';
import ImageTextBlock from 'components/ImageTextBlock';
import BrandImg from 'images/strike-brand.png';
import EstateAgency from 'images/services-ea.png';
import Mortgages from 'images/services-mortgages.png';
import Conveyancing from 'images/services-conveyancing.png';
import Moving from 'images/services-moving.png';

const Services = () => {
  return (
    <Layout showFixedCta>
      <SEO
        title="Our Services | Mortgages, EPCs, Conveyancing, Moving | Strike"
        description="We're Here To Get You Moving, From The First Property Search Until The Keys Are In Your Hand. We Offer A Range Of Services To Help Make That Next Dream Move A Reality."
        canonical="/services"
        metaImage={BrandImg}
      />
      <Hero
        title={
          <>
            We&apos;re here to
            <br /> get you moving
          </>
        }
        subtitle="From your first property search until the keys are in your hand, we offer a range of services to help make that next dream move a reality
        "
      />
      <ImageTextBlock
        title="Estate Agency"
        cta="Learn more"
        link="/selling/how-it-works"
        image={EstateAgency}
        reverse
      >
        A fully-managed sale, from start to finish. You&apos;ll have a team of
        property experts and can manage your listing in your mobile hub with a
        click. And it&apos;s free. Really, truly free.
      </ImageTextBlock>
      <ImageTextBlock
        title="Mortgages"
        cta="Learn more"
        link="/services/mortgages"
        image={Mortgages}
      >
        We&apos;ve got the fast, stress-free mortgage advice you&apos;ve been
        looking for.
      </ImageTextBlock>
      <ImageTextBlock
        title="Conveyancing"
        cta="Learn more"
        link="/services/conveyancing"
        image={Conveyancing}
        reverse
      >
        Moving is stressful enough &mdash; let us take care of the details. Our
        conveyancing service makes the legal side of buying and selling a
        breeze. One flat rate, no hidden fees.
      </ImageTextBlock>
      <ImageTextBlock
        title="Moving Services"
        cta="Learn more"
        link="/services/moving-services"
        image={Moving}
      >
        Have enough on your plate? Let us take care of the loose ends. From mail
        forwarding to changing your broadband, we&apos;ve got you covered. We
        can even arrange the moving van. Let&apos;s give you one less thing to
        worry about.
      </ImageTextBlock>
    </Layout>
  );
};

export default Services;
